import React, {createRef, useState} from "react"
import images from "./images";
import emailjs from "emailjs-com";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import { SERVICE_ID,USER_ID,TEMPLATE_ID } from "./GLOBAL";


const SmallContactForm = props => {

    const myForm = React.useRef();

    const [error, setError] = useState('d-none');
    const [cap, setCap] = useState(false);
    const [loading, setLoading] = useState('d-none');

    const key="6LfmH10aAAAAAINIs-6M9PQNybbAm30lVtzaWpP6"
    const recaptchaRef = createRef();

    const onChangeCaptcha = value =>
    {
        setError('d-none');
        setCap(true);
    }


    const handleSubmit = event => {
        setLoading('d-inline-block');
        recaptchaRef.current.reset();
        event.preventDefault();

        if(!cap)
        {
            setError('d-block');
            setLoading('d-none');
            return false
        }
         emailjs.init(USER_ID)
        emailjs
            .send(SERVICE_ID, TEMPLATE_ID, {
                my_html  : '<ul>\n' +
                    '    <li><strong>From: </strong>'+myForm.current.name.value+'</li>\n' +
                    '    <li><strong>Email Id: </strong>'+myForm.current.email.value+'</li>\n' +
                    '    <li><strong>Phone No.: </strong>'+myForm.current.phone.value+'</li>\n' +
                    '    <li><strong>Company Name: </strong>'+myForm.current.company_name.value+'</li>\n' +
                    '    <li><strong>Category Of Product: </strong>'+myForm.current.category_of_product.value+'</li>\n' +
                    '    <li><strong>Service Required: </strong>'+myForm.current.service_required.value+'</li>\n' +
                    '    <li><strong>License Required: </strong>'+myForm.current.license_required.value+'</li>\n' +
                    '    <li><strong>Comment: </strong><br/>'+myForm.current.other.value+'</li>\n' +
                    '</ul>'
            })
            .then(
                function(response) {

                    let m = $("#formReponseModel");
                    m.modal('show');
                    m.find(".success-class").show()
                    m.find(".error-class").hide()
                    setLoading('d-none');
                    myForm.current.reset()
                    setCap(false);
                    setTimeout(function (){m.modal('hide');}, 5000)
                },
                function(error) {

                    let m = $("#formReponseModel");
                    m.modal('show');
                    m.find(".error-class").show()
                    m.find(".success-class").hide()
                    m.find(".error-class").text("Error: "+error.text)
                    setLoading('d-none');
                    setCap(false);
                    setTimeout(function (){m.modal('hide');}, 5000)
                }
            )
    }

    return (
        <div style={{boxShadow:'15px 24px 23px 0 rgba(0, 0, 0, 0.13)', borderRadius:'10px'}}>
            <div style={{
                backgroundImage: 'url(' + images.greyWave + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="p-4">
                    <div>
                        <h2 className={"fsm-theme-heading theme-color-blue"}>Enquire Now</h2>
                        <p className="fsm-theme-small-text">To enquire about our services please complete the form below
                            and we will be in tough with you as soon as possible </p>
                    </div>
                    <div className="pb-3">
                        <img src={images.customerCare} className="img-fluid"/>
                    </div>
                </div>
            </div>
            <form className="pl-4 pr-4" action="!#" method="POST" ref={myForm} onSubmit={handleSubmit}>

                <div className="form-row">

                    <div className="form-group col-12">
                        <label>Name*</label>
                        <input type="text" className="form-control" name="name"  required/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="inputEmail4">Email*</label>
                        <input type="email" className="form-control" name="email" id="inputEmail4"
                               placeholder="eg. contact@gmail.com" required/>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="inputphone">Phone No. / Mobile No.*</label>
                        <input type="text" className="form-control" name="phone" id="inputphone" placeholder="+91-XXXXXXXXXX" required/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="inputCompany">Company Name</label>
                        <input type="text" className="form-control" name="company_name" id="inputCompany"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="input_category_of_product">Category Of Product</label>
                        <select id="input_category_of_product" name="category_of_product" className="form-control"
                                defaultValue={'N/A'}>
                            <option value="N/A">Select</option>
                            <option value="Caffeinated Beverages">Caffeinated Beverages</option>
                            <option value="Carbonated and Non-Carbonated Beverages">Carbonated and Non-Carbonated
                                Beverages
                            </option>
                            <option value="Tea, Coffee and Chicory">Tea, Coffee and Chicory</option>
                            <option value="Vegetable Oils">Vegetable Oils</option>
                            <option value="Pickles, Curries and Chutneys">Pickles, Curries and Chutneys</option>
                            <option value="Pulp, Purees, Concentrates, Syrups, Sauces and Pastes">Pulp, Purees,
                                Concentrates, Syrups, Sauces and Pastes
                            </option>
                            <option value="Thermally processed fruits, cocktails, soups, juices and beverages">Thermally
                                processed fruits, cocktails, soups, juices and beverages
                            </option>
                            <option value="Butter, Ghee and Milk Fats">Butter, Ghee and Milk Fats</option>
                            <option value="Dahi, Yoghurt, Lassi, Cheese">Dahi, Yoghurt, Lassi, Cheese</option>
                            <option value="Milk & Cream">Milk & Cream</option>
                            <option value="Chocolates">Chocolates</option>
                            <option value="Lozenges and Chewing Gums">Lozenges and Chewing Gums</option>
                            <option value="Sugar Boiled Confectionery">Sugar Boiled Confectionery</option>
                            <option value="Condiments and Additives">Condiments and Additives</option>
                            <option value="Proprietary Foods">Proprietary Foods</option>
                            <option value="infant food">infant food</option>
                            <option value="Novel food products">Novel food products</option>
                            <option value="Probiotics & prebiotics">Probiotics & prebiotics</option>
                            <option value="Supplements and Nutraceuticals">Supplements and Nutraceuticals</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="input_service_required">Service Required</label>
                        <select id="input_service_required" name="service_required" className="form-control" defaultValue={'N/A'}>
                            <option value="N/A">Select</option>
                            <option value="Claim Compliance">Claim Compliance</option>
                            <option value="Label Compliance">Label Compliance</option>
                            <option value="Product Compliance">Product Compliance</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="input_license_required">License Required</label>
                        <select id="input_license_required" name="license_required" className="form-control" defaultValue={'N/A'}>
                            <option value="N/A">Select</option>
                            <option value="Form C">Form C</option>
                            <option value="Claim Approval">Claim Approval</option>
                            <option value="FSSAI NOC">FSSAI NOC</option>
                            <option value="Legal Metrology">Legal Metrology</option>
                            <option value="Custom Clearance">Custom Clearance</option>
                            <option value="Non- Specified product / Ingredient Approval">Non- Specified product / Ingredient Approval</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputComment">Comment</label>
                        <textarea className="form-control" name="other" id="inputComment" placeholder="Comment." rows="4" cols="3"> </textarea>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12">
                        <ReCAPTCHA sitekey={key} size={'compact'} ref={recaptchaRef} onChange={onChangeCaptcha} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12 text-right">
                        <button type="submit"
                                className="btn btn-outline-light rounded-pill pl-4 theme-bg-blue pr-4 fsm-theme-small-text ml-auto">SUBMIT

                            <div className={"spinner-border ml-2 "+loading} role="status" style={{height: '20px',width: '20px'}}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className={"form-row "+error}>
                    <div className="form-group col-md-12">
                        <p className="fsm-theme-small-text text-danger">Error : &nbsp; Please complete captcha first</p>
                    </div>
                </div>


            </form>

        </div>
    )
}
export default SmallContactForm
