import React from "react"
import images from "./images";
import CategoriesList from "./categories-list";
import RegulatoryAssociatedFields from "./regulatory-associated-fields";

export default function  InnerSnipCard(data) {

    return (
        <>
            <div className="row mb-3">
                <div className="col-12">
                    <div className="h-100" style={{overflow:'hidden', backgroundColor:'#fff',boxShadow:'20px 18px 32px 5px rgb(0 0 0 / 4%)'}}>


                        <div className="card border-0" style={{backgroundColor:'transparent',backgroundImage:'url('+images.backHalfTriangleIconGrey+')', backgroundPosition:'top right', backgroundRepeat:'no-repeat',backgroundSize:'auto'}}>

                            <div className="position-absolute h-100 w-100" style={{backgroundImage:'url('+images.backDotsIconDark+')', backgroundPosition:'2px 2px', backgroundRepeat:'no-repeat',backgroundSize:'100px auto', filter:'opacity(0.4)'}} > </div>

                            <div className="pt-4 pl-4 pr-4 pb-2">
                                <div className="row pt-4">
                                    <div className="col pb-4">
                                        <CategoriesList size={"fsm-theme-heading-big font-800"} color={"theme-color-blue"} data={{c1:data.data.categories !== null ? data.data.categories.map(e => e.name) : [], c2:data.data.compliance_services, c3:data.data.licenses_and_approvals}} key={2}/>
                                    </div>
                                </div>

                                <div className="card-text fsm-theme-small-text" dangerouslySetInnerHTML={{ __html: data.data.excerpt}} />

                                {
                                    data.data.acf !== undefined && data.data.acf !== null ? <RegulatoryAssociatedFields data={data.data.acf}  key={1}/>: null
                                }

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
