import React from "react"
import {Link} from "gatsby";
const Tag = data => {
    return (
        <>
            <div className="col px-1">
                <Link className="fsm-theme-small-text btn-pill text-decoration-none bg-color-dark-blue-hover hover-text-white  py-1   px-2 text-center bd-1 bdr-0-hover bd-normal my-1 d-block w-auto text-nowrap text-capitalize" to={new URL(data.data.link).pathname}>
                    {data.data.name}
                </Link>
            </div>
        </>
    )
}

export default Tag
